<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <form @keyup.enter="login">
          <div
            slot="no-body"
            class="full-page-bg-color"
          >
            <div class="vx-row no-gutter justify-center items-center">
              <div class="vx-col hidden lg:block lg:w-1/2">
                <img
                  v-lazy="'@/assets/images/pages/login.png'"
                  alt="login"
                  class="mx-auto"
                >
              </div>

              <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                <div class="p-8 login-tabs-container">
                  <div class="vx-card__title mb-4">
                    <h4 class="mb-4 bold">
                      تسجيل الدخول
                    </h4>
                    <!--                  <p>Welcome back, please login to your account.</p>-->
                  </div>
                  <div>
                    <vs-input
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="الإيميل"
                      v-model="email"
                      class="w-full"
                    />

                    <vs-input
                      type="password"
                      name="كلمة السر"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="كلمة السر"
                      v-model="password"
                      class="w-full mt-6"
                    />

                    <div class="flex flex-wrap justify-between my-5">
                      <vs-checkbox
                        v-model="checkbox_remember_me"
                        class="mb-3"
                      >
                        تذكرني
                      </vs-checkbox>
                    </div>
                    <vs-button
                      class="float-left mb-10"
                      @click="login()"
                    >
                      تسجيل الدخول
                    </vs-button>
                    <br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {vapidKey} from "@/firebase";

export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
      fcmToken: ''
    };
  },
  methods: {
    login: function () {
      // validation
      if (!this.email) {
        this.$vs.notify({
          title: 'الرجاء إدخال الإيميل',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      }
      if (!this.password) {
        this.$vs.notify({
          title: 'الرجاء إدخال كلمة السر',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      }
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        }
      };

      this.$vs.loading();

      this.$store.dispatch('login', payload)
        .then(() => {
          this.fcmToken = this.getFcmToken();
          this.$vs.loading.close();
          this.$router.push({name: 'home'});
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'حدث خطأ',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        });
    },
    getFcmToken() {
      this.$messaging.getToken({vapidKey})
        .then((currentFcmToken) => {
          if (currentFcmToken) {
            this.fcmToken = currentFcmToken;
            this.$store.dispatch('addDeviceToken', this.fcmToken);
          }
        })
        .catch(error => console.log(error));
      return this.fcmToken;
    },
  },
};
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1
    }

    .bg-twitter {
      background-color: #00aaff
    }

    .bg-google {
      background-color: #4285F4
    }

    .bg-github {
      background-color: #333
    }
  }
}
</style>
